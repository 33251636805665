<template>
  <div class="wrap" @click="clickPositionImg()">
    <img :src="positionImg" alt="" />
    <!-- 引导 -->
    <div class="guide" v-if="showGuide"></div>
  </div>
</template>

<script>
import { getRedirectUrl } from "../api/index.js";
import { getUserInfo } from "../api/index.js";
import { getLocationParams } from "../api/index.js";
import { getLocation } from "../api/index.js";
import { checkProjectConfig } from "../api/index.js";
import { journal } from "../api/index.js";
import { codeCheck } from "../api/index.js";
// import wx from "weixin-jsapi";
const wx = require("weixin-js-sdk");
export default {
  name: "authorize",
  data() {
    return {
      positioning: true, //是否定位
      projectCode: "", //项目码
      qrCode: "", //二维码信息
      code: "", //微信返回code
      imgUrl: process.env.VUE_APP_BASE_positionimgUrl,
      positionImg: require("../assets/position.png"),
      status: 1,
      showGuide: false,
    };
  },
  methods: {
    getInfo() {
      let search = location.search.slice(1);
      let arr = search.split("&");
      let result = {};
      let that = this;
      arr.forEach(function (item) {
        let itemArr = item.split("=");
        result[itemArr[0]] = itemArr[1];
      });
      console.log("获取code", result.code);
      this.code = result.code;
      if (result.code) {
        //调用后端接口
        getUserInfo({ code: result.code })
          .then((res) => {
            sessionStorage.setItem("openId", res.data.openId);
            this.getLocation();
          })
          .catch(() => {
            this.showGuide = true;
          });
      } else {
        getRedirectUrl({
          projectCode: this.projectCode,
          qrCode: this.qrCode,
        }).then((res) => {
          let appId = res.data.appID;
          let redirectUri = encodeURIComponent(
            window.location.href.split("#")[0]
          );
          let scope = res.data.scope;
          let httpUrl = res.data.oauthCodePrefix;
          let state = that.projectCode + "/" + that.qrCode;
          location.href =
            httpUrl +
            `appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect `;
        });
      }
    },
    getLocation() {
      var that = this;
      if (this.positioning) {
        // 请求地理位置参数
        getLocationParams({ projectCode: that.projectCode }).then((res) => {
          var timestamp = res.data.timestamp;
          var appId = res.data.appId;
          var nonceStr = res.data.nonceStr;
          var signature = res.data.signature;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature, // 必填，签名，见附录1
            jsApiList: ["getLocation", "hideOptionMenu"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          wx.ready(() => {
            wx.hideOptionMenu();
            wx.getLocation({
              debug: true,
              type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                getLocation({
                  lat: res.latitude,
                  lng: res.longitude,
                }).then(() => {
                  that.turntable();
                });
              },
              cancel: function () {
                // getLocation({
                //   lat: "",
                //   lng: "",
                // }).then(() => {
                //   that.turntable();
                // });
              },
            });
          });
        });
      } else {
        that.turntable();
      }
    },
    // 跳转转盘页面
    turntable() {
      const newUrl = process.env.VUE_APP_Router_API + this.$route.path;
      window.history.replaceState("", "", newUrl);
      this.$router.push({ path: "/turntable", replace: true });
    },
    // 项目校验
    projectConfi() {
      var that = this;
      checkProjectConfig({ projectCode: this.projectCode }).then((res) => {
        this.positioning = res.data.location;
        this.status = res.data.status;
        if (res.data.status == 1) {
          codeCheck({ qrCode: that.qrCode }).then((res) => {
            if (res.data.status == 10) {
              that.getInfo();
            } else {
              if (res.data.verifyPageType == 1) {
                that.$router.push({
                  path: "/qcodeState",
                  query: {
                    factory: res.data.factory,
                    sku: res.data.boxcode,
                    packageCreatTime: res.data.indexnum,
                    packet: res.data.produceName,
                    status: res.data.status,
                    directType: res.data.directType,
                    platecode: res.data.platecode,
                    type: res.data.directType,
                  },
                });
              } else if (res.data.verifyPageType == 0) {
                that.$router.push({
                  path: "/codeState",
                  query: {
                    factory: res.data.factory,
                    sku: res.data.sku,
                    packageCreatTime: res.data.packageCreatTime,
                    packet: res.data.packet,
                    status: res.data.status,
                    directType: res.data.directType,
                  },
                });
              }
            }
          });
          return;
        } else if (res.data.status == 2) {
          //已结束
          that.positionImg = that.imgUrl + res.data.endImg;
          return;
        } else if (res.data.status == 0) {
          //未开始
          that.positionImg = that.imgUrl + res.data.createImg;
          return;
        }
      });
    },
    clickPositionImg() {
      var maidian = {
        userVisit: "20",
      };
      journal(maidian);
    },
  },
  mounted: function () {
    this.projectCode = this.$route.params.projectCode;
    this.qrCode = this.$route.params.qrCode;
    sessionStorage.setItem("projectCode", this.projectCode);
    sessionStorage.setItem("qrCode", this.qrCode);
    let search = location.search.slice(1);
    let arr = search.split("&");
    let result = {};
    let that = this;
    arr.forEach(function (item) {
      let itemArr = item.split("=");
      result[itemArr[0]] = itemArr[1];
    });
    console.log("获取code", result.code);
    var maidian = {
      userVisit: "13",
    };

    if (result.code) {
      this.getInfo();
    } else {
      this.projectConfi();
      journal(maidian);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.guide {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 750px;
    height: 364px;
    background: url('../assets/guide.gif') no-repeat top center / 100% auto;
    pointer-events: none;
  }
.wrap {
  width: 100%;
  background: #fdcb00;
  display: block;
  padding: 0;
  margin: 0;

  img {
    width: 100%;
    display: block;
  }
}
</style>
